import React from 'react'

import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section'
import { Animation } from 'gatsby-theme-portfolio-minimal/src/components/Animation'
import { useLocalDataSource } from 'gatsby-theme-portfolio-minimal/src/sections/About/data'
import * as classes from 'gatsby-theme-portfolio-minimal/src/sections/About/style.module.css'

import gif from '../../../content/images/puppetmaester1.gif'

const AboutSection = ({ sectionId, heading }) => {
    const response = useLocalDataSource();
    const data = response.allAboutMarkdown.sections[0];
    console.log(gif)
    return (
        <Animation type="fadeUp">
            <Section anchor={sectionId} heading={heading}>
                <div className={classes.About}>
                    <div className={classes.Description}>...using the popular puppeteer.js library. But now what? Running it on your own computer is not fast or reliable - and certainly not scalable. And where do you save the scraped data?<br/><br/><strong>ScraperScript</strong> is the answer to these questions!</div>
                    <Animation type="fadeLeft" delay={200}>
                        <div className={classes.ImageWrapper}>
                            <img className='shadow-lg' src={gif} alt="Product image"/>
                        </div>
                    </Animation>
                </div>
            </Section>
        </Animation>
    )
}

export default AboutSection