import React from 'react';
import { useSiteConfiguration } from 'gatsby-theme-portfolio-minimal/src/hooks/useSiteConfiguration';
import * as classes from './style.module.css';
import logo from '../../../content/images/Favicon.png'

interface LogoProps {
    fontSize?: string;
    color?: string;
}

export function Logo(props: LogoProps): React.ReactElement {
    const siteConfiguration = useSiteConfiguration();
    const fontSize = props.fontSize || '2rem';
    const color = props.color || 'var(--primary-color)';

    return (
        <div className={classes.Logo} aria-roledescription="logo" style={{ fontSize, color }}>
            <img className={classes.Image} height={50} width={50} src={logo}/>{siteConfiguration.logo.text}
        </div>
    );
}
