import React from "react";
import {
  // AboutSection,
  ArticlesSection,
  ContactSection,
  HeroSection,
  InterestsSection,
  // Page,
  ProjectsSection,
  Seo,
} from "gatsby-theme-portfolio-minimal";
import { Page } from "../components/Page";

// import ContactSection  from '../sections/ContactSection'
import AboutSection from "../sections/AboutSection";
import PreviewSection from "../sections/PreviewSection";
import SignupSection from "../sections/SignupSection";
import PricingSection from "../sections/PricingSection";

export default function IndexPage() {
  return (
    <>
      <Seo title="PuppetMaester - Browser automation made easy" />
      <Page useSplashScreenAnimation className="static">
        <HeroSection sectionId="hero" />
        {/* <ArticlesSection sectionId="articles" heading="Latest Articles" sources={['Medium']} /> */}
        <AboutSection sectionId="about" heading="You've written a web scraping script..." />
        <PreviewSection sectionId="preview" heading="Web scraping - without the hassle" />
        <InterestsSection sectionId="features" heading="Features" />
        {/* <ProjectsSection sectionId="details" heading="No code needed" /> */}
        {/* <ContactSection sectionId="github" heading="Sounds interesting?" /> */}
        <PricingSection sectionId="pricing" heading="Pricing"/>
        <SignupSection/>
      </Page>
    </>
  );
}
