import React from 'react'

import { Animation } from 'gatsby-theme-portfolio-minimal/src/components/Animation'
import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section'

import Button from '../../components/Button'

const SignupSection = (sectionId, header) => {
  return (
    <Section anchor={sectionId}>
      <Animation type='fadeUp'>
        <div className='grid place-items-center max-w-2xl mx-auto'>
          <div>
            <h3 className='mb-8 text-3xl place-self-left'>Sounds interesting?</h3>
            <p className='mb-8'>Of course it does! Be the first to know when we launch in <strong>beta</strong>. Sign up to out email
            list and we'll let you know when we release and what new features we're working on.</p>
          </div>
            <Button link="/form.html" title="Sign up for Beta"/>
        </div>
      </Animation>
    </Section>
  )
}

export default SignupSection