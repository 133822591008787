import React from 'react'
import { Animation } from 'gatsby-theme-portfolio-minimal/src/components/Animation'
import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section'

import PricingCard from '../../components/PricingCard'

const PricingSection = ({ sectionId, heading }) => {
  return (
    <Animation type='fadeUp'>
        <Section anchor={sectionId}>
            <h3 className='mb-8 text-3xl place-self-left'>{heading}</h3>
            <Animation type="fadeLeft" delay={200}>
                <div className='flex flex-col md:flex-row column'>
                    <PricingCard title="Pay as you go" description="Great for personal use and smaller projects. Only pay for what you use when your scripts run, no strings attached." price="$1" priceSub="per hour."/>    
                    <PricingCard title="Pro plan" description="Monthly subscription unlocking all premium features. 80+ hours of runtime and custom solutions." price="$49" priceSub="per month."/>
                </div>
            </Animation>
        </Section>
    </Animation>
  )
}

export default PricingSection