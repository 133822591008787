import React from 'react'

import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section'
import { Animation } from 'gatsby-theme-portfolio-minimal/src/components/Animation'
import { useLocalDataSource } from 'gatsby-theme-portfolio-minimal/src/sections/About/data'
import * as classes from './style.module.css'

import gif from '../../../content/images/puppetmaester1.gif'

const PreviewSection = ({ sectionId, heading }) => {
    const response = useLocalDataSource();
    const data = response.allAboutMarkdown.sections[0];
    console.log(gif)
    return (
        <Animation type="fadeUp">
            <Section anchor={sectionId} heading={heading}>
                <div className={classes.About}>
                    <div className={classes.Description}>
                        ...using the popular puppeteer.js library. But now what? Running it on your own computer is not fast or reliable - and certainly not scalable. And where do you save the scraped data? <strong>ScraperScript</strong> is the answer to these questions!
                        </div>
                    <Animation type="fadeLeft" delay={200}>
                        {/* <div className={classes.ImageWrapper}>
                            <img className='shadow-lg' src={gif} alt="Product image"/>
                        </div> */}
                        <div className={[classes.ImageWrapper]}>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ZnuwB35GYMY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </Animation>
                </div>
            </Section>
        </Animation>
    )
}

export default PreviewSection