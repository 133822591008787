import React from 'react'

const PricingCard = ({ title, description, price, priceSub }) => {
  return (
    <div className='max-w-sm overflow-hidden rounded-2xl border-black mx-auto shadow-lg mt-8 transform ease-in-out transition duration-200 hover:-translate-y-1'>
        <div className='grid'>
            <div className='px-8 pt-4 mx-auto'>
                    <span className='text-6xl'>{price}</span>
                    <p className='text-center'>{priceSub}</p>
            </div>
            <div className='px-8 pb-4'>
                <h3>{title}</h3>
                <p>{description}</p>
            </div> 
        </div>
    </div>
  )
}

export default PricingCard